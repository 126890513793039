import firebase, { collection, getDocs } from '../../src/config/firebase';
require('firebase/auth')

const db = firebase.firestore();

const freightService = {

    async deleteFreight(id){

        db.collection('freight')
        .doc(id)
        .delete()
        .catch(error => {
            alert('Erro ao deletar esse frete, contate o adm do sistema')
            console.log('Erro ao deletar esse frete')

        });
    },

    async saveStatusQueueFreight(id, idQueue, status){
        console.log("[saveStatusQueueFreight] Salvando status na fila do frete " + status)
        
        var data = {
            status: status
        }

        db.collection('freight')
            .doc(id)
            .collection('queue')
            .doc(idQueue)
            .update(data)
            .catch(error => {
                console.log(error)
            });
    },

    async saveDriverQueueFreight(idFreight, idDriver, data){
        console.log("[saveDriverQueueFreight] Salvando status na fila do frete ")
        
        console.log(data)
        
        db.collection('freight')
                .doc(idFreight)
                .collection('queue')
                .doc(idDriver)
                .set(data)
            .catch(error => {
                console.log(error)
            });
    },

    async deleteDriverQueueFreight(idFreight, idDriver) {
        console.log("[deleteDriverQueueFreight] Removendo motorista da fila do frete");
    
        try {
            await db.collection('freight')
                .doc(idFreight)
                .collection('queue')
                .doc(idDriver)
                .delete();
            console.log(`Motorista ${idDriver} removido da fila do frete ${idFreight}`);
        } catch (error) {
            console.error("Erro ao remover motorista da fila do frete:", error);
        }
    },

    async updateStatusFreight(idFreight, status, codeStatus){
        console.log("[updateStatusFreight] Salvando status no frete" + status + "Codigo: " + codeStatus)
        
        var chosenStatus = {
            describe: status,
            code: codeStatus
        }
        
        var data = {
            status: chosenStatus
        }

        

        db.collection('freight')
            .doc(idFreight)
            .update(data)
            .catch(error => {
                console.log(error)
            });
    },

    async searchCurrentWay(idFreight){


        // return {
        //     latitude: -8.071562122327835,
        //     longitude: -34.927362241277,
        //     timestamp: 1725113176452
        // }

        return db.collection('freight')
            .doc(idFreight)
            .collection('positions')
            .orderBy('timestamp', "desc")
            .limit(1)  
        .get();
    },

    //#busca a posição mais antiga do frete, posição inicial
    async searchFirstWay(idFreight){
        return await db.collection('freight')
            .doc(idFreight)
            .collection('positions') 
            .orderBy('timestamp') 
            .limit(1) 
        .get();
    },
    //#busca as posições após a ultima recebida 
    async searchLatestPositions(idFreight, lastPositionReceived){

        return await db.collection('freight')
            .doc(idFreight)
            .collection('positions') 
            .orderBy('timestamp') 
            .startAfter(lastPositionReceived) 
        .get();
    },

    async getAllFreights() {
        return db.collection('freight').orderBy('createData', 'desc').get();
    },

    async getFreightsInProgress() {
        return db.collection('freight')
        .where("status.describe", "==", "Em transito")
        .orderBy('createData', 'desc')
        // .where('status.describe', 'not-in', ['Em Analise do motorista', 'Em Analise de perfil', 'Pendente de contratação'])
        .get()
        // .where('status.describe', 'not-in', ['Em Analise do motorista'])
    },

    async freightAllFinalizado() {
        return db.collection('freight')
            .where("status.describe", "==", "Finalizado")
            .get();
    },

    async getFreightsByDriverId(idDriver) {

        var retorno = await db.collection('freight')
        .where('freight.getDriverFreight.uidDriver', '==', idDriver)
        .get()
        
        return retorno
    },

    async getFreightById(idFreight) {
        return db.collection('freight')
        .doc(idFreight)
        .get()
    },

    async getPositionsByFreight(idFreight) {
        return db
        .collection('freight')
        .doc(idFreight)
        .collection('positions')
        .orderBy('timestamp', "desc")
        .get();
    },

    async getStoppingPointsByFreight(idFreight) {
        return db.collection('freight')
        .doc(idFreight)
        .collection('stopping_points')
        .orderBy('stop_order')
        .get()
    },

    async freightInTravel(){
        return db.collection('freight').where('status.describe', '==', 'Em transito')
        .get();
    },

    async freightDelivered(idFreight){
        return db
        .collection('freight')
        .doc(idFreight)
        .collection('stopping_points')
        .where('concluded', '==', true)
        .get()
    },

    async freightAllDelivered(){

        let qtdDelivery = 0;

        db.collection('freight').get().then(async item=> {

            item.docs.forEach(async frete => {

                db.collection('freight').doc(frete.id).collection('stopping_points')
                .get().then(async (result) => {
                    qtdDelivery = qtdDelivery + result.size;
                });

                return qtdDelivery;
            });

        }).catch(error=> {
            console.error(error)
        });


        // return db
        // .collection('freight').doc()
        // .collection('stopping_points')
        // .where('concluded', '==', true)
        // .get()
    },

    async freightWithOcurrency(){

    //    const stopPointsRef = db
    //     .collection('freight_history')
    //     .get();

    return db
        .collection('freight')
        .doc()
        .collection('stopping_points')
        .get();




        // console.log(stopPointsRef)

        

        // return db
        // .doc('/freight/stopping_points')
        // .doc()
        // .collection('stopping_points')
        // .where('observation', '!=', '')
        // .get();
    },

    // async freightWithOcurrencyHisotry(){
    //     return db
    //     .collection('freight_history')
    //     .doc()
    //     .collection('stopping_points')
    //     .where('observation', '!=', "")
    //     .get()
    // },

    async allFreightsFinished(){
        return db
        .collection('freight')
        .where("status.describe", "==", "Finalizado")
        .get()
    }

}

export default freightService;