import React from 'react';
import { Modal, Button, List, Typography, Avatar } from 'antd';
import { ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


const DialogFileDriver = ({ driversInFila, openModal, handleClose }) => {
    return (
        <Modal
            title="Motoristas na Fila"
            open={openModal}
            onCancel={handleClose}
            footer={[
                <Button key="ok" type="primary" onClick={handleClose}>
                    OK
                </Button>
            ]}
            centered // Centraliza o modal na tela
        >
            {driversInFila.length < 1 ? (
                <Typography.Text type="danger">
                    Não há motorista em espera para esse frete no momento.
                </Typography.Text>
            ) : (
                <List
                    dataSource={driversInFila}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                  avatar={
                                    <Avatar
                                        style={{
                                            backgroundColor: '#1890ff', // Cor de fundo do avatar
                                            color: '#fff', // Cor do texto
                                        }}
                                    >
                                        {item.driver_name[0].toUpperCase()} {/* Primeira letra do nome */}
                                    </Avatar>
                                }
                                title={<Typography.Text>{item.driver_name}</Typography.Text>}
                                description={`Na fila de espera desde: ${new Date(
                                    item.registrationInLineTime.seconds * 1000
                                ).toLocaleString('pt-BR')}`}

                            />
                                <Link to={'/driverList/' + item.driver_user}>
                                        <EditOutlinedIcon />
                                </Link>
                        </List.Item>
                    )}
                />
            )}
        </Modal>
    );
};

export default DialogFileDriver;



// import React from 'react';
// import { Box, CardActionArea, CardContent, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
// import { Avatar, Card, Divider, List} from '@mui/material';
// import { Link } from 'react-router-dom';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import { format } from 'date-fns';
// import { Modal } from 'antd';

// export default function DialogFileDriver({ driversInFila, openModal, handleClose }) {

//     const style = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 400,
//       };

//     return (
//         <>

//         <Modal
//             title={<p>Loading Modal</p>}
//             // loading={loading}
//             open={openModal}
//             onCancel={handleClose}
//         >
//             <Box sx={{ ...style, width: 600 }}>
//                 <Card sx={{ maxWidth: 600 }}>
//                     <CardActionArea>
//                         <CardContent>
//                             <Typography gutterBottom variant="h5" component="div">
//                             Motoristas na fila
//                             </Typography>
//                             {
//                             driversInFila.length < 1 ?
//                                 <Typography gutterBottom color={"error"} component="div">
//                                     Não há motorista em espera para esse frete no momento
//                                 </Typography>
//                                 :
//                                 driversInFila.map(item => (
//                                 <div className='row' key={item.id}>
//                                     <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
//                                         <ListItem alignItems="flex-start">
//                                             <ListItemAvatar>
//                                             <Avatar alt={item.driver_name} />
//                                             </ListItemAvatar>
//                                             <ListItemText
//                                             primary={item.driver_name}
//                                             secondary={
//                                                 <React.Fragment>
//                                                 <Typography
//                                                     sx={{ display: 'inline' }}
//                                                     component="span"
//                                                     variant="body2"
//                                                     color="text.primary"
//                                                 >
//                                                     Na fila de espera desde: {format(new Date(item.registrationInLineTime.seconds * 1000), 'dd/MM/yyyy HH:mm:ss')}
//                                                 </Typography>
//                                                 </React.Fragment>
//                                             }
//                                             />
//                                             <ListItemText>
//                                             <Link to={'/driverList/' + item.driver_user}>
//                                                 <EditOutlinedIcon />
//                                             </Link>
//                                             </ListItemText>
//                                         </ListItem>
//                                         <Divider variant="inset" component="li" />
//                                     </List>
//                                 </div>
//                                 ))
//                             }
//                         </CardContent>
//                     </CardActionArea>
//                 </Card>
//             </Box>
//       </Modal>

//         {/* <Modal
//             open={openModal}
//             onClose={handleClose}
//             aria-labelledby="parent-modal-title"
//             aria-describedby="parent-modal-description"
//         >
//             <Box sx={{ ...style, width: 600 }}>
//                 <Card sx={{ maxWidth: 600 }}>
//                     <CardActionArea>
//                         <CardContent>
//                             <Typography gutterBottom variant="h5" component="div">
//                             Motoristas na fila
//                             </Typography>
//                             {
//                             driversInFila.length < 1 ?
//                                 <Typography gutterBottom color={"error"} component="div">
//                                     Não há motorista em espera para esse frete no momento
//                                 </Typography>
//                                 :
//                                 driversInFila.map(item => (
//                                 <div className='row' key={item.id}>
//                                     <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
//                                         <ListItem alignItems="flex-start">
//                                             <ListItemAvatar>
//                                             <Avatar alt={item.driver_name} />
//                                             </ListItemAvatar>
//                                             <ListItemText
//                                             primary={item.driver_name}
//                                             secondary={
//                                                 <React.Fragment>
//                                                 <Typography
//                                                     sx={{ display: 'inline' }}
//                                                     component="span"
//                                                     variant="body2"
//                                                     color="text.primary"
//                                                 >
//                                                     Na fila de espera desde: {format(new Date(item.registrationInLineTime.seconds * 1000), 'dd/MM/yyyy HH:mm:ss')}
//                                                 </Typography>
//                                                 </React.Fragment>
//                                             }
//                                             />
//                                             <ListItemText>
//                                             <Link to={'/driverList/' + item.driver_user}>
//                                                 <EditOutlinedIcon />
//                                             </Link>
//                                             </ListItemText>
//                                         </ListItem>
//                                         <Divider variant="inset" component="li" />
//                                     </List>
//                                 </div>
//                                 ))
//                             }
//                         </CardContent>
//                     </CardActionArea>
//                 </Card>
//             </Box>
//         </Modal> */}

//         </>
//     );
// }
