import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import PersistentDrawerLeft from "../../components/navMenu/NavMenu";
import MapComponent from "../../components/MAPA-COMP/mapa-comp";
import firebase from "../../config/firebase";
import freightService from "../../service/freight.service";

require("firebase/auth");

export default function ViewerFreights() {
  const db = firebase.firestore();
  const { id } = useParams();

  const [points, setPoints] = useState([]);
  const [positions, setPositions] = useState([]);
  const [freightData, setFreightData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stoppingPointsUrl, setStoppingPointsUrl] = useState("");
  const [positionsUrl, setPositionsUrl] = useState("");

  // Função para calcular a distância entre duas coordenadas (Haversine Formula)
  const calculateDistance = (coord1, coord2) => {
    const [lat1, lon1] = coord1;
    const [lat2, lon2] = coord2;
    const R = 6371; // Raio da Terra em km

    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distância em km

    return distance;
  };

  // Função para gerar o link de coordenadas filtradas e unificadas
  const generateGoogleMapsLink_ = (positions) => {
    // 23 a 25
    const baseUrl = "https://www.google.com/maps/dir/";

    // Função para agrupar coordenadas muito próximas e com timestamps próximos
    const filteredPositions = positions.reduce((acc, current, index, arr) => {
      if (index === 0) {
        acc.push(current); // Adiciona o primeiro ponto
        return acc;
      }

      const prevPosition = acc[acc.length - 1]; // Último ponto no array de acumulados
      const distance = calculateDistance(current.coords, prevPosition.coords);
      const timeDifference = Math.abs(
        current.timestamp - prevPosition.timestamp
      );

      // Se a distância for maior que 0.1 km ou a diferença de tempo for maior que 5 minutos, adiciona como um novo ponto
      if (distance > 0.1 || timeDifference > 5 * 60 * 1000) {
        acc.push(current);
      } else {
        // Se for muito próximo, substitui o ponto anterior com a média dos pontos agrupados
        const avgLat = (prevPosition.coords[0] + current.coords[0]) / 2;
        const avgLon = (prevPosition.coords[1] + current.coords[1]) / 2;
        prevPosition.coords = [avgLat, avgLon];
        prevPosition.timestamp = Math.max(
          prevPosition.timestamp,
          current.timestamp
        ); // Mantenha o timestamp mais recente
      }

      return acc;
    }, []);

    // Transformar as coordenadas filtradas em string para o Google Maps
    const coordsString = filteredPositions
      .map((position) => position.coords.join(","))
      .join("/");

    return `${baseUrl}${coordsString}`;
  };
  const generateGoogleMapsLink = (positions) => {
    const baseUrl = "https://www.google.com/maps/dir/";

    // Limite de pontos para o Google Maps (25 é o máximo permitido)
    const maxPoints = 25;

    if (positions.length <= maxPoints) {
      // Se o número de posições for menor ou igual ao limite, basta usar todas as posições
      const coordsString = positions
        .map((position) => position.coords.join(","))
        .join("/");
      return `${baseUrl}${coordsString}`;
    }

    // Selecionar os primeiros, últimos e pontos intermediários proporcionalmente
    const step = Math.floor(positions.length / (maxPoints - 1)); // -1 para incluir o último ponto
    const selectedPositions = [];

    // Adiciona o primeiro ponto
    selectedPositions.push(positions[0]);

    // Adiciona pontos intermediários proporcionalmente
    for (let i = step; i < positions.length - 1; i += step) {
      selectedPositions.push(positions[i]);
    }

    // Adiciona o último ponto
    selectedPositions.push(positions[positions.length - 1]);

    // Gerar a string para o Google Maps com os pontos selecionados
    const coordsString = selectedPositions
      .map((position) => position.coords.join(","))
      .join("/");

    return `${baseUrl}${coordsString}`;
  };

  const fetchFreightData = async () => {
    try {
      // const freightDocRef = db.collection("freight").doc(id);
      // const freightDoc = await freightDocRef.get();
      const freightDoc = await freightService?.getFreightById(id);

      if (!freightDoc.exists) {
        throw new Error("Documento não encontrado");
      }

      const freightData = {
        id: freightDoc.id,
        ...freightDoc.data(),
      };

      // const subcollectionSnapshot = await freightDocRef
      //   .collection("stopping_points")
      //   .get();

      const subcollectionSnapshot = await freightService?.getStoppingPointsByFreight(
        id
      );

      const subcollectionData = subcollectionSnapshot.docs.map((subDoc) => ({
        id: subDoc.id,
        ...subDoc.data(),
      }));

      console.log(subcollectionData);

      setFreightData({
        ...freightData,
        subcollectionData,
      });

      const pontos = subcollectionData.map((item) => ({
        coords: [item.latitude, item.longitude],
        popupText: `
          <strong>${item.name}</strong><br/>
          ${item.logradouro}, ${item.number}, ${item.city} - ${item.uf}, ${item.cep}
        `,
        events: item.events || [], // Inclui os eventos associados ao ponto de parada
        operation: item.operation || [],
        concluded: item.concluded || false,
      }));

      // Adiciona os pontos de parada ao estado
      setPoints(pontos);
      // Gerar a URL de pontos de parada
      setStoppingPointsUrl(generateGoogleMapsLink(pontos));
    } catch (err) {
      setError(err);
      console.error("Erro ao buscar dados do frete:", err);
    } finally {
      setLoading(false);
    }
  };

  const searchLatestPositions = async (lastPositionReceived) => {
    try {
      console.log(lastPositionReceived);
      // const latestPositionsSnapshot = await db
      //   .collection("freight")
      //   .doc(id)
      //   .collection("positions")
      //   .orderBy("timestamp")
      //   .startAfter(lastPositionReceived)
      //   .get();

      const latestPositionsSnapshot = await freightService?.searchLatestPositions(
        id,
        lastPositionReceived
      );

      const latestPositions = latestPositionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Total positions fetched:", latestPositions.length);

      // Atualizando o estado com as últimas coordenadas recebidas
      const coordenadas = latestPositions.map((position) => ({
        coords: [position.latitude, position.longitude],
        timestamp: position.timestamp,
      }));

      // setPositions((_positions) => [..._positions, ...coordenadas]);
      setPositions((_positions) => {
        const novasPosicoes = coordenadas.filter(novaPosicao =>
          !_positions.some(pos => pos.timestamp === novaPosicao.timestamp)
        );
        return [..._positions, ...novasPosicoes];
      });
      

      // Gerar a URL de posições
      const positionCoords = coordenadas.map((pos) => pos.coords);
      setPositionsUrl(generateGoogleMapsLink([...positions, ...coordenadas]));

      return latestPositions;
    } catch (error) {
      console.error("Erro ao buscar últimas posições:", error);
      return null;
    }
  };

  useEffect(() => {
    fetchFreightData();
    searchLatestPositions(); // Chamando a função para buscar as últimas posições
  }, []);

  useEffect(() => {
    if (points.length > 0) {
      console.log("Pontos no estado:", points);
    }
  }, [points]); // Verificar sempre que `points` for atualizado

  if (useSelector((state) => state.usuarioLogado) <= 0) {
    return <Navigate to="/login" />;
  }

  return (
    <PersistentDrawerLeft
      divOpen={
        <div className="freight-content-details">
          <div className="container">
            {loading ? (
              <p>Carregando...</p>
            ) : error ? (
              <p>Erro ao carregar dados: {error.message}</p>
            ) : points.length > 0 ? (
              <>
                <MapComponent
                  points={points}
                  idFreight={id}
                  searchLatestPositions={searchLatestPositions}
                />
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    gap: 10,
                  }}
                >
                  <div style={{ width: "50%", marginBottom: 5 }}>
                    <h3>Coordenadas coletadas</h3>
                    <a
                      href={positionsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver no Maps
                    </a>
                    <table
                      style={{ borderWidth: 1 }}
                      className="table-coordenadas"
                    >
                      <tr>
                        <th>Data/Horário</th>
                        <th>Coordenadas</th>
                      </tr>
                      {positions.map((position, index) => (
                        <tr>
                          <td>
                            {isNaN(position?.timestamp)
                              ? "Data inválida"
                              : new Date(position.timestamp).toLocaleString()}
                          </td>
                          <td>
                            <a
                              href={`https://www.google.com/maps/dir/${position.coords[0]},${position.coords[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {position.coords[0]}, {position.coords[1]}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  <div style={{ width: "50%", marginBottom: 5 }}>
                    <h3>Pontos de Parada</h3>
                    <a
                      href={stoppingPointsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver no Maps
                    </a>
                    <table className="table-pontos-de-parada">
                      <tr>
                        <th>Ordem</th>
                        <th>Detalhes</th>
                      </tr>

                      {freightData &&
                      freightData?.subcollectionData?.length > 0 ? (
                        freightData.subcollectionData.map((data, index) => (
                          <tr key={data.id}>
                            <td>
                              <strong>{data?.stop_order}</strong>
                            </td>
                            <td>
                              <br />
                              <strong>Nome:</strong> {data.name}
                              <br />
                              <strong>Logradouro:</strong> {data.logradouro},{" "}
                              <strong>Número:</strong> {data.number}
                              <br />
                              <strong>Cidade:</strong> {data.city},{" "}
                              <strong>UF:</strong> {data.uf}
                              <br />
                              <strong>CEP:</strong> {data.cep}
                              <br />
                              <strong>Coordenadas:</strong>
                              <a
                                href={`https://www.google.com/maps/dir/${data.latitude},${data.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.latitude}, {data.longitude}
                              </a>
                              <br />
                              {data?.events?.length > 0 ? (
                                <>
                                  <strong>Ocorrências:</strong> <br />
                                  <ul>
                                    {data?.events?.map((event, idx) => (
                                      <li key={idx}>
                                        <strong>Registrado em:</strong> 
                                        {event?.created_at &&
                                        typeof event.created_at.seconds ===
                                          "number"
                                          ? new Date(
                                              event.created_at.seconds * 1000
                                            ).toLocaleString()
                                          : "Data inválida"}
                                        <br />
                                        <strong>Evento: </strong>
                                        Codigo: {event?.event?.cod || ""} -{" "}
                                        {event?.event?.desc || ""}
                                        <br />
                                        <strong>Descrição: </strong>
                                        {event?.description || ""}
                                        <br />
                                        {event?.images.map((img) => {
                                          return (
                                            <img
                                              src={img}
                                              style={{
                                                width: "80%",
                                                align: "center",
                                                paddingTop: "10px",
                                              }}
                                              alt="img event"
                                            />
                                          );
                                        })}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              ) : (
                                "Sem eventos"
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <li>Nenhum dado de ponto de parada disponível.</li>
                      )}
                    </table>
                  </div>
                </section>
              </>
            ) : (
              <p>Nenhum ponto de parada encontrado.</p>
            )}
          </div>
        </div>
      }
    />
  );
}
